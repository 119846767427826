import React from 'react'
import GrideCard from './GrideCard'

const AllPlaced = () => {
  return (
    <div className='All-cources'>
            <h1>All Courses</h1>
             
            <GrideCard/>
             
    </div>
  )
}

export default AllPlaced