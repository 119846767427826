import React, { useState } from 'react'


function VerifyCertificate() {
  const [ver,setVer]=useState('');
  const submitVerForm=()=>{
    if(ver==''){
      alert("Please Enter Code")
    }else{
      //api request on which we are working
      alert("Entered Verificattion Code is Wrong")
    }
  }
  return (
    <div>
        <header
          style={{ 
            height: '25vw',
            display: 'flex',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <div className="header-content">
            <h1>Verify Certificates here</h1>
          </div>
        </header>
        <div className='verify-body'>
            <h1>Verify Certificate</h1>
            <p>Verify cetificates issued by us by simply entering Verification Code written on certificate.</p>
            <div style={{padding:'20px 5px',maxWidth:'400px'}}>
              <input type='text' onChange={(e)=>setVer(e.target.value)}  className='verify-input' placeholder='Enter Code'/>
            </div>
            <div style={{marginBlock:'10px'}}>
              <button className='sbmt-btn' type='button' onClick={()=>submitVerForm()}>
                Submit
              </button>
            </div>
        </div>
    </div>
  )
}

export default VerifyCertificate