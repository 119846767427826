import React from "react";
import { Link } from "react-router-dom";



const Footer = () => {
  return (
    <div>
      <footer className="footer">
         
        <div className='footer-bottom'>
          <ul>
            <li>
              <ul>
                <li><a href="https://acetians.com/about" target="_blank">
                    About Us
                  </a></li>
                <li>
                 <a href="https://acetians.com/contact" target="_blank">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="https://acetians.com/careers" target="_blank">
                    Careers
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>
                  <Link to="/verify-certificate">
                  Verify Certificate
                  </Link>
                   
                   
                </li>
                <li>
                  <a href="https://acetians.com/contact" target="_blank">
                    Bussines Enquiry
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
